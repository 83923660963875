<template>
  <Multiselect
    v-model="localModalValue"
    mode="tags"
    placeholder="Choose your stack"
    :close-on-select="false"
    :filter-results="false"
    :min-chars="1"
    :resolve-on-load="false"
    :delay="300"
    :searchable="true"
    :options="
      async function (query) {
        return await searchChange(query)
      }
    "
    @close="handleClose"
    @clear="handleClose"
  >
    <template #tag="{ option, handleTagRemove, disabled }">
      <div
        class="multiselect-tag is-user"
        :style="{
          background: validEmail(option.value) === true ? '#005d9d' : '#d93942',
        }"
      >
        {{ option.value }}
        <span
          v-if="!disabled"
          class="multiselect-tag-remove"
          @mousedown.prevent="handleTagRemove(option, $event)"
        >
          <span class="multiselect-tag-remove-icon"></span>
        </span>
      </div>
    </template>
    <template #option="{ option }">
      <div class="optionWrapper">
        <p class="optionWrapper__email">
          {{ option.value }}
        </p>
        <p class="optionWrapper__name">{{ option.label }}</p>
      </div>
    </template>
  </Multiselect>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import email from '@/utils/validator/rules/email'

export default {
  components: {
    Multiselect,
  },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    placeholder: { type: String, default: '' },
    invalid: { type: Boolean, default: false },
  },
  emits: ['update:modelValue', 'blur'],
  data() {
    return {
      options: [],
      loader: false,
      count: 5,
    }
  },
  computed: {
    localModalValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
  methods: {
    validEmail(val) {
      return email(val)
    },
    async searchChange(query) {
      if (!query) return []
      return await this.$http.sendCommunication
        .searchEmails({ params: { search: query } })
        .then((response) => {
          return response.data.map((el) => ({
            value: el.email,
            label: el.full_name,
          }))
        })
        .catch(() => {
          return []
        })
    },
    handleClose() {
      this.$emit('blur')
    },
  },
}
</script>

<style lang="scss">
.optionWrapper {
  display: flex;
  flex-direction: column;

  &__email {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
  }

  &__name {
    margin: 0;
    font-size: 14px;
  }
}

.multiselect-option.is-pointed {
  background: #fff;

  &:hover {
    background: #f3f4f6;
  }
}

.multiple-tag {
  background: none;
  color: #303c54;
}

.invalid {
  border-color: #e55353 !important;
  padding-left: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e55353'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e55353' stroke='none'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

.invalid .multiselect-caret {
  display: none !important;
}
</style>

import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import CInputTags from './components/CInputTags'
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import {parseError} from '@/utils/api'
import Multiselect from '@vueform/multiselect'

export default {
    components: {
        ConfirmDialog,
        CInputTags,
        Multiselect,
    },
    inject: ['toast'],
    async beforeRouteLeave() {
        if (!this.checkForm() && !this.isSend && !this.isCanceled) {
            await this.$refs.confirmDialog
                .confirm({
                    text:
                        'You have unsaved changes.\n' +
                        'Are you sure, you want to leave the page?',
                    confirmText: 'Leave',
                    cancelText: 'Cancel',
                    reverse: true,
                })
                .then((response) => {
                    if (response) {
                        return true
                    } else {
                        return Promise.reject(false)
                    }
                })
        } else {
            return true
        }
    },
    data() {
        return {
            mountedComponent: false,
            search: '',
            availableEmails: [],
            selectedTemplate: '',
            loading: false,
            isSend: false,
            isCanceled: false,
            templates: [],
            shortCodes: [],
            account: {},
            origin: {},
            form: {
                email_subject: '',
                email_message: '',
                to: [],
                cc: [],
            },
            validator: {},
            validationErrors: {},
        }
    },
    watch: {
        'form.email_subject': function (val) {
        },
    },
    mounted() {
        this.mountedComponent = true
        this.loading = true
        this.$nextTick(() => {
            this.getData()
        }).finally(() => (this.loading = false))
    },
    methods: {
        checkForm() {
            let cc = this.origin.cc.length === this.form.cc.length
            let to = this.origin.to.length === this.form.to.length
            let msg = this.origin.email_message === this.form.email_message
            let sub = this.origin.email_subject === this.form.email_subject
            return cc && to && msg && sub
        },
        handleChangeField(field) {
            this.validationErrors[field] = this.validator.validate(
                field,
                this.form[field],
            )
        },
        setRules() {
            this.validator = validator({
                to: [
                    (val) => (val.length >= 1 ? true : 'validations.required'),
                    rules.required,
                    rules.emails,
                ],
                cc: [rules.emails],
                email_subject: [
                    rules.required,
                    rules.strMax(255),
                    rules.shortCodePlaceholder(this.shortCodes),
                ],
                email_message: [
                    rules.required,
                    rules.strMax(2000),
                    rules.shortCodePlaceholder(this.shortCodes),
                ],
            })
        },
        handleChangeTemplate(val) {
            this.validationErrors = {}

            this.form.email_subject = val ? val.subject : ''
            this.form.email_message = val ? val.message : ''

            this.handleChangeField('email_subject')
            this.handleChangeField('email_message')
        },
        getData() {
            this.$http.sendCommunication.searchEmails().then(res => {
                this.availableEmails = res.data
                const filteredEmails = this.availableEmails.filter(user => {
                    const field = String(user[this.$route.query.field || 'id'])
                    return this.$route.query.users.includes(field)
                })
                this.form.to = filteredEmails.map(user => user.email)
            })

            this.$http.sendCommunication.getData().then((response) => {
                this.templates = response.data.templates.map((item) => {
                    return {value: item.id, text: item.name, template: item}
                })
                this.shortCodes = response.data.shortCodes
                this.account = response.data.account
                this.origin = JSON.parse(JSON.stringify(this.form))
                this.setRules()
            })
        },
        async handleCancel() {
            await this.$refs.confirmDialog
                .confirm({
                    text: 'Cancel send communication?',
                    info: 'All the data will be lost. You will be the redirected to the previous page, where Send Communication Action was enabled',
                    cancelText: 'Cancel Sending',
                    confirmText: 'Return to editing',
                    size: 'lg',
                    reverse: true,
                })
                .then((res) => {
                    if (!res) {
                        this.isCanceled = true
                        this.$router.go(-1)
                    }
                })
        },
        handleSend() {
            const validationResult = this.validator.validateAll(this.form)
            if (!validationResult.hasErrors) {
                this.loading = true
                this.$http.sendCommunication
                    .sendNotify(this.form)
                    .then((res) => {
                        this.isSend = true
                        this.toast('success', res.data.message)
                        this.$router.go(-1)
                    })
                    .catch((err) => {
                        err = parseError(err)
                        if (err.status === 422) {
                            this.validationErrors = err.validationMessages
                        } else {
                            this.toast('error', err.message)
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            } else {
                this.validationErrors = validationResult.validationErrors
            }
        },
    },
    computed: {
        emailOptions() {
            return this.availableEmails.map(x => x.email)
        }
    }
}

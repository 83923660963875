<template>
  <div class="sendCommunication">
    <teleport v-if="mountedComponent" to="#users-header">
      <h1 class="sendCommunication__heading">Send Communication</h1>
    </teleport>

    <teleport v-if="mountedComponent" to="#header-nav">
      <CBreadcrumbItem>
        <router-link :to="{ name: 'Users' }">Users</router-link>
      </CBreadcrumbItem>
      <CBreadcrumbItem active>Send Communication</CBreadcrumbItem>
    </teleport>

    <CCard>
      <CCardBody v-loading="loading" style="min-height: calc(100vh - 160px)">
        <ConfirmDialog ref="confirmDialog" />

        <CRow>
          <CCol xs="12" lg="6">
            <CRow :xs="{ gutterY: 3 }">
              <CCol xs="12" class="mt-2 pc-hide">
                <p>
                  This page allows you to send emails to multiple selected
                  users. In case any errors will occur while sending, error
                  reports will be sent on email {{ account.email }}.
                </p>
              </CCol>
              <CCol xs="12">
                <CFormLabel class="d-flex justify-content-between">
                  Email Template Name
                  <router-link :to="{ name: 'SettingEmailTemplates' }">
                    Manage templates
                  </router-link>
                </CFormLabel>
                <Multiselect
                  v-model="selectedTemplate"
                  class="msTemplate"
                  :options="templates"
                  label="text"
                  value-prop="template"
                  :can-clear="true"
                  :searchable="true"
                  @change="handleChangeTemplate"
                />
              </CCol>
              <CCol xs="12">
                <CFormLabel>Email Subject</CFormLabel>
                <CFormInput
                  v-model="form.email_subject"
                  :invalid="!!validationErrors.email_subject"
                  @blur="handleChangeField('email_subject')"
                />
                <CFormText
                  v-if="validationErrors.email_subject"
                  style="color: red"
                >
                  {{ validationErrors.email_subject }}
                </CFormText>
              </CCol>
              <CCol xs="12">
                <CFormLabel>From</CFormLabel>
                <CFormText style="color: rgb(103, 122, 137)">
                  {{ account.company ? account.company.name : '' }} {{ '<'
                  }}{{ account.email }}>
                </CFormText>
              </CCol>
              <CCol xs="12">
                <CFormLabel>To</CFormLabel>
                <Multiselect
                  searchable
                  v-model="form.to"
                  mode="tags"
                  :options="emailOptions" />
<!--                <CInputTags-->
<!--                  v-model="form.to"-->
<!--                  :invalid="!!validationErrors.to"-->
<!--                  @blur="handleChangeField('to')"-->
<!--                />-->
                <CFormText v-if="validationErrors.to" style="color: red">
                  {{ validationErrors.to }}
                </CFormText>
              </CCol>
<!--              <CCol xs="12">-->
<!--                <CFormLabel>CC</CFormLabel>-->
<!--                <CInputTags v-model="form.cc" @blur="handleChangeField('cc')" />-->
<!--                <CFormText v-if="validationErrors.cc" style="color: red">-->
<!--                  {{ validationErrors.cc }}-->
<!--                </CFormText>-->
<!--              </CCol>-->
              <CCol xs="12">
                <CFormLabel>Email Message</CFormLabel>
                <CFormTextarea
                  v-model="form.email_message"
                  rows="5"
                  style="resize: none"
                  :invalid="!!validationErrors.email_message"
                  @blur="handleChangeField('email_message')"
                />
                <CFormText
                  v-if="validationErrors.email_message"
                  style="color: red"
                >
                  {{ validationErrors.email_message }}
                </CFormText>
              </CCol>
            </CRow>
          </CCol>
          <CCol xs="6" class="mt-2 mobile-hide">
            <p>
              This page allows you to send emails to multiple selected users. In
              case any errors will occur while sending, error reports will be
              sent on email {{ account.email }}.
            </p>
          </CCol>

          <CCol xs="12" class="mt-3">
            <CButton
              class="sendCommunication-send"
              color="primary"
              @click="handleSend"
              >Send</CButton
            >
            <CButton
              class="sendCommunication-cancel"
              color="primary"
              variant="outline"
              @click="handleCancel"
              >Cancel</CButton
            >
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script src="./script.js"></script>
<style lang="scss">
@import 'style';
</style>
